@font-face {
  font-family: "Fira Sans";
  src: url("/firasans.woff2");
  font-display: swap;
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Fira Sans", monospace;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: "Fira Sans", monospace;
}

::-webkit-scrollbar {
  width: 2px;
  height: 0.3em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb {
  background-color: rgb(54, 120, 206);
}

pre::-webkit-scrollbar {
  display: none;
}

pre {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.profile-image {
  border-radius: 50%;
}
